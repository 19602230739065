<template>
  <v-container class="pa-0 mb-6" fluid>
    <v-overlay
      v-show="promotionStatusRequest.value === 'loading-getPromotionById'"
      opacity="0.5"
    >
      <v-progress-circular
        color="grey lighten-2"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <transition name="component-trans" mode="out-in">
      <router-view></router-view>
    </transition>
  </v-container>
</template>

<script>
export default {
  computed: {
    promotionStatusRequest() {
      return this.$store.getters["PROMOTION/statusRequest"];
    }
  }
};
</script>
